import { Component, Input, OnChanges, Output } from '@angular/core';
import { Routes } from 'src/shared/models/route';
import { Line } from 'src/shared/models/line';
import { RouteService } from 'src/shared/services/route.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-route',
  templateUrl: './route.component.html',
  styleUrls: ['./route.component.css']
})
export class RouteComponent  implements OnChanges {
 public clickedRoute: any[];
showComponent = true;

  @Input() line: Line;
  routes: Routes[];

  constructor(private routeService: RouteService, private modalService: NgbModal) { }

  ngOnChanges() {
    if (this.line === undefined) { return; }
    this.line.getRelationArray(Routes, 'routes').subscribe((routes: Routes[]) => {
      this.routes = routes;
    });
  }

  childRouteClicked(array: any[]) {
    this.clickedRoute = array;
    this.showComponent = false;
  }

}

