import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularHalModule } from 'angular4-hal';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ExternalConfigurationService } from './external-configuration-service.service';
import { NgbModule, NgbTimeStruct, NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouteComponent } from './route/route.component';
import { HomeComponent } from './home/home.component';
import { StanceComponent } from './stance/stance.component';
import { DirectionComponent } from './route/direction/direction.component';
import { RouteChildComponent } from './route/route-child/route-child.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { TestComponent } from './test/test.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from 'src/auth/auth.guard';
import { AngularFontAwesomeModule } from 'angular-font-awesome';

@NgModule({
  declarations: [
    AppComponent,
    RouteComponent,
    HomeComponent,
    StanceComponent,
    DirectionComponent,
    RouteChildComponent,
    TestComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularHalModule.forRoot(),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    AutocompleteLibModule,
    AngularFontAwesomeModule
  ],
  providers: [{ provide: 'ExternalConfigurationService', useClass: ExternalConfigurationService }, AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
