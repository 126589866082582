import { Resource } from 'angular4-hal';

export class Stop extends Resource {
    ordinal: number;
    duration: number;
    stanceIndex: string;
    stanceName: string;
    stance: string;

    // fetchStanceName() {
    //     this.getRelation(Stance, 'stance').subscribe(e => {
    //         this.stanceName = e.index + ' ' + e.name;
    //     });
    // }
}
