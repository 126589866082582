import { Component, OnInit } from '@angular/core';
import { Stance } from 'src/shared/models/stance';
import { StanceService } from 'src/shared/services/stance.service';
import { HalOptions, HalParam } from 'angular4-hal';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit {
  keyword = 'description';
  public stances: Stance[];
  stance: Stance;

  constructor(private stanceService: StanceService) { }
  ngOnInit(): void {
  }
    onChangeSearch(search: string) {
      this.stance = null;
      if (search === '') { return; }
      const paramSearch: HalParam = {key : 'searchString', value : search.replace(/\s+/g, ',' )};
      const paramProjection: HalParam = {key : 'projection', value : 'description'};
      const options: HalOptions = {params : [paramSearch, paramProjection]};
      this.stanceService.search('keywords', options).subscribe(stances => {
        this.stances = stances;
      });
    }
    onFocused(e) {
      // do something
    }

    onSelected(stance: Stance){
      this.stance = stance;
    }
}
