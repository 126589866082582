import { Component, OnInit, Input, OnChanges, Injectable } from '@angular/core';
import { Routes } from 'src/shared/models/route';
import { Direction } from 'src/shared/models/direction';
import { Stop } from 'src/shared/models/stop';
import { RouteService } from 'src/shared/services/route.service';
import { NgbModal, NgbTimeAdapter, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { Departure } from 'src/shared/models/Departure';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { Stance } from 'src/shared/models/stance';
import { StanceService } from 'src/shared/services/stance.service';
import { HalParam, HalOptions } from 'angular4-hal';

@Injectable()
export class NgbTimeStringAdapter extends NgbTimeAdapter<string> {

  fromModel(value: string): NgbTimeStruct {
    if (!value) {
      return null;
    }
    const split = value.split(':');
    return {
      hour: parseInt(split[0], 10),
      minute: parseInt(split[1], 10),
      second: parseInt(split[2], 10)
    };
  }

  toModel(time: NgbTimeStruct): string {
    if (!time) {
      return null;
    }
    return `${this.pad(time.hour)}:${this.pad(time.minute)}:${this.pad(time.second)}`;
  }

  private pad(i: number): string {
    return i < 10 ? `0${i}` : `${i}`;
  }
}

@Component({
  selector: 'app-direction',
  templateUrl: './direction.component.html',
  styleUrls: ['./direction.component.css'],
  providers: [{provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter}]
})
export class DirectionComponent implements OnChanges {

  @Input() array: any[];
  route: Routes;
  directionSign: string;
  direction: Direction;
  // stops: Stop[];
  stop: Stop;
  time: '01:00:00';
  day: number;
  seasson: string;
  stanceName: string;
  stanceIndex: string;
  ordinal: number;
  duration: number;
  departure: Departure;
  keyword = 'description';
  public stances: Stance[];
  stance: Stance;
  error: any;

  constructor(
    private routeService: RouteService,
    private modalService: NgbModal,
    private stanceService: StanceService
    ) { }

  ngOnChanges() {
    this.route = this.array[0];
    this.directionSign = this.array[1];
    this.direction = this.directionSign === 'A' ? this.route.directionA : this.route.directionB;
    // this.stops = this.direction.stops;
  }

  onChangeSearch(search: string) {
    this.stance = null;
    if (search === '') { return; }
    const paramSearch: HalParam = {key : 'searchString', value : search.replace(/\s+/g, ',' )};
    const paramProjection: HalParam = {key : 'projection', value : 'description'};
    const options: HalOptions = {params : [paramSearch, paramProjection]};
    this.stanceService.search('keywords', options).subscribe(stances => {
      this.stances = stances;
    });
  }
  onFocused(e) {
    // do something
  }

  onSelected(stance: Stance) {
    this.stance = stance;
  }
  setDay( day: number) {
    this.day = day;
  }

  setSeason(season: string) {
    this.seasson = season;
  }

  onDelete(departure: Departure) {
    const ind = this.direction.departures.indexOf(departure);
    this.direction.departures.splice(ind, 1);
  }

  onDeleteStop(stop: Stop) {
    const ind = this.direction.stops.indexOf(stop);
    this.direction.stops.splice(ind, 1);
  }

  open(content, departure: Departure) {
    this.departure = departure;
    const modalRef = this.modalService.open(content);
  }

  openStop(content, stop: Stop) {
    this.stance = null;
    this.stop = stop;
    const modalRef = this.modalService.open(content);
  }

  openError(contentError: any) {
    const modalRef = this.modalService.open(contentError);
  }

  onUpdateStop(stop: Stop) {
    if (this.stance != null){
      stop.stance = this.stance._links.self.href;
      stop.stanceIndex = this.stance.index;
      stop.stanceName = this.stance.name;
    }
  }

  addDeparture() {
    const d = new Departure();
    d.time = this.time;
    d.day = this.day;
    d.seasson = this.seasson;
    this.direction.departures.push(d);
  }

  addStop() {
    const s = new Stop();
    s.ordinal = this.ordinal;
    s.duration = this.duration;
    s.stanceIndex = this.stance.index;
    s.stanceName = this.stance.name;
    s.stance = this.stance._links.self.href;
    this.direction.stops.push(s);
  }

  save() {
    if (this.directionSign === 'A'){
      this.route.directionA = this.direction;
    } else {
      this.route.directionB = this.direction;
    }
    console.log(this.route);
    this.routeService.patch(this.route).subscribe((r: Routes) => {
      console.log(r); // vratiti korisnika nazad na prethodnu komponentu
    }, (httperror) => {
      this.error = httperror.error;
    });
  }
}
