import { Component, OnInit } from '@angular/core';
import { Stance } from 'src/shared/models/stance';
import { StanceService } from 'src/shared/services/stance.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-stance',
  templateUrl: './stance.component.html',
  styleUrls: ['./stance.component.css']
})
export class StanceComponent implements OnInit {
  stances: Stance[];
  closeResult: string;
  tindex: string;
  tname: string;
  stance: Stance;

  constructor(
    private stanceService: StanceService,
    private modalService: NgbModal,
    private router: Router) { }

    ngOnInit(): void {
      this.getAllStances();
    }

    getAllStances() {
      this.stanceService.getAll()
        .subscribe((stances: Stance[]) => {
          this.stances = stances;
        });
    }

    onDelete(stance: Stance) {
      this.stanceService.delete(stance).subscribe((e) => {
        const ind = this.stances.indexOf(stance);
        this.stances.splice(ind, 1);
      });
    }

    onUpdate(stance: Stance) {
      this.stanceService.patch(stance).subscribe((e) => {
      });
    }
    add() {
      const stance = new Stance();
      stance.index = this.tindex;
      stance.name = this.tname;
      this.stanceService.create(stance).subscribe((l: Stance) => {
        this.stances.push(l);
      });
    }

    open(content, stance: Stance) {
      this.stance = stance;
      const modalRef = this.modalService.open(content);
    }

    back() {
      this.stanceService.prev().subscribe((stances: Stance[]) => {
        this.stances = stances;
      });
    }
    next() {
      this.stanceService.next().subscribe((stances: Stance[]) => {
        this.stances = stances;
      });
    }
}
