import { Injectable, Injector } from '@angular/core';
import { RestService } from 'angular4-hal';
import { Routes } from '../models/route';

@Injectable({
  providedIn: 'root'
}
)
export class RouteService extends RestService<Routes> {

  constructor(injector: Injector) {
    super(Routes, 'routes', injector);
   }
}
