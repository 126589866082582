import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Line } from 'src/shared/models/line';
import { LineService } from 'src/shared/services/line.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  lines: Line[];
  closeResult: string;
  tindex: string;
  tname: string;
  line: Line;
  nazivLinije: string;
  brojLinije: number;
  nijeValidno = true;

  @Output() eventClicked = new EventEmitter();

  constructor(
    private lineService: LineService,
    private modalService: NgbModal,
    private router: Router) {
  }

  ngOnInit(): void {
    this.getAllLines();
  }
 
  getAllLines() {
    this.lineService.getAll()
      .subscribe((lines: Line[]) => {
        this.lines = lines;
      });
  }

  onDelete(line: Line) {
    this.lineService.delete(line).subscribe((e) => {
      const ind = this.lines.indexOf(line);
      this.lines.splice(ind, 1);
    });
  }

  back() {
    this.lineService.prev().subscribe((lines: Line[]) => {
      this.lines = lines;
    });
  }

  next() {
    this.lineService.next().subscribe((lines: Line[]) => {
      this.lines = lines;
    });
  }
  clickedEvent;
  showComponent = false;
  goToRoutes(line: Line) {
    this.clickedEvent = line;
    this.showComponent = true;
    // this.eventClicked.emit(line);
  }
  closeIt() {
    this.showComponent = false;
  }
  childEventClicked(line: Line) {
   
  }
  open(content, line: Line) {
    this.line = line;
    const modalRef = this.modalService.open(content);
  }

  onUpdate(line: Line) {
    this.lineService.patch(line).subscribe((e) => {
    });
  }

  // add() {
  //   const line = new Line();
  //   line.index = this.tindex;
  //   line.name = this.tname;

  //   this.lineService.create(line).subscribe((l: Line) => {
  //     this.lines.push(l);
  //   });
  // }

  dodaj() {
    // dodaj samo ako postoji naziv linije
    if (this.tindex !== '' && this.tname !== '') {
      const line = new Line();
      line.index = this.tindex;
      line.name = this.tname;
      this.lineService.create(line).subscribe((l: Line) => {
        this.lines.push(l);
      });
    }
  }

  onChange(value) {
    // ako je razlicito od null i undefined i nije prazan string onda mozes je disabled za dugme false
    if (this.tindex !== '' && this.tname !== '') {
      this.nijeValidno = false;
    } else {
      // u suprotnom omoguci dugme da radi
      this.nijeValidno = true;
    }
  }

  public gotoProductDetails(url, id) {
    this.router.navigate([url, id]).then( (e) => {
      if (e) {
        console.log('Navigation is successful!');
      } else {
        console.log('Navigation has failed!');
      }
    });
  }
}

