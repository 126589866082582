import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RouteComponent } from './route/route.component';
import { HomeComponent } from './home/home.component';
import { RouteChildComponent } from './route/route-child/route-child.component';
import { DirectionComponent } from './route/direction/direction.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from 'src/auth/auth.guard';

// const routes: Routes = [];
const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'route',
    component: RouteComponent,
  },
  {
    path: 'routeChild',
    component: RouteChildComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'direction',
    component: DirectionComponent ,
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
