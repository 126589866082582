import { Injectable, Injector } from '@angular/core';
import { Line } from '../models/line';
import { RestService } from 'angular4-hal';

@Injectable({
  providedIn: 'root'
})
export class LineService extends RestService<Line> {

  constructor(injector: Injector) {
    super(Line, 'lines', injector);
   }
}
