import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { Routes } from 'src/shared/models/route';
import { RouteService } from 'src/shared/services/route.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Direction } from 'src/shared/models/direction';
import { Line } from 'src/shared/models/line';

@Component({
  selector: 'app-route-child',
  templateUrl: './route-child.component.html',
  styleUrls: ['./route-child.component.css']
})

export class RouteChildComponent implements OnChanges {

  @Input() routes: Routes[];
  @Input() line: Line;
  route: Routes;
  index: string;
  name: string;
  @Output() routeClicked = new EventEmitter();
  array: any;
  rindex: string;
  rname: string;
  nijeValidno = true;
  brojRute: string;
  nazivRute: string;

  constructor(private routeService: RouteService, private modalService: NgbModal) { }

  ngOnChanges() {
  }

  onDelete(routes: Routes) {
    this.routeService.delete(routes).subscribe((e) => {
      const ind = this.routes.indexOf(routes);
      this.routes.splice(ind, 1);
    });
  }

  addRoute() {
    console.log(this.line);
    if (this.rindex !== '' && this.rname !== '') {
      const c = new Routes();
      c.index = this.rindex;
      c.name = this.rname;
      c.line = this.line._links.self.href;
      this.routeService.create(c).subscribe((z: Routes) => {
        this.routes.push(z);
      });
    }
  }

  onChange(value) {
    // ako je razlicito od null i undefined i nije prazan string onda mozes je disabled za dugme false
    if (this.rindex !== '' && this.rname !== '') {
      this.nijeValidno = false;
    } else {
      // u suprotnom omoguci dugme da radi
      this.nijeValidno = true;
    }
  }

  open(content, route: Routes) {
    console.log(route.directionA.startPoint);
    console.log(route.directionB.endPoint);
    this.route = route;
    const modalRef = this.modalService.open(content);
  }

  onUpdate(route: Routes) {
    this.routeService.patch(route).subscribe((e) => {
    });
  }

  goToDirectionA(route: Routes) {
    const array = [route, 'A'];
    this.routeClicked.emit(array);
  }

  goToDirectionB(route: Routes) {
    const array = [route, 'B'];
    this.routeClicked.emit(array);  }
}
