import { Injectable, Injector } from '@angular/core';
import { RestService } from 'angular4-hal';
import { Stance } from '../models/stance';

@Injectable({
  providedIn: 'root'
})
export class StanceService extends RestService<Stance> {

  constructor(injector: Injector) {
    super(Stance, 'stances', injector);
   }
  }
