import { Component, OnInit } from '@angular/core';
import { Line } from 'src/shared/models/line';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {

  public clickedEvent: Line;
  showComponent = true;

  ngOnInit() {
  }

  childEventClicked(line: Line) {
    this.clickedEvent = line;
    this.showComponent = false;
  }

}
