import { Resource } from 'angular4-hal';
import { Direction } from './direction';
import { Departure } from './Departure';

export class Routes extends Resource {
    index: string;
    name: string;
    directionA: Direction;
    directionB: Direction;
    departure: Departure;
    line: string;
}

