import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  myGroup: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
  ) { }
  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.myGroup = new FormGroup({
      firstName: new FormControl()
    });
  }
  onSubmit() {
    this.submitted = true;
    // debugger
    if (this.loginForm.controls.username.value === 'savo' && this.loginForm.controls.password.value === 'savo1234') {
      sessionStorage.setItem('logedUser', 'true');
      this.router.navigate(['home']);
    }
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
  }
}
